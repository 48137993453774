<template>
  <div id="app">
    <div class="absolute right-0 z-50 p-4 cursor-pointer" @click="toggle">
      <inline-svg
        class="tablet:hidden w-9 cursor pointer"
        :src="require(`@/assets/images/manue.svg`)"
      />
    </div>
    <headerElem></headerElem>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <FooterElem></FooterElem>

    <Drawer @close="toggle" align="right" :closeable="true">
      <div v-if="open">
        <ul
          class="w-full max-w-8xl m-auto h-[50px] items-center font-bold text-xl middle:text-2xl"
        >
          <li @click="toggle" class="mb-3 w-full text-center">
            <router-link to="/"
              ><img src="@/assets/images/logo.png" class="m-auto"
            /></router-link>
          </li>
          <li  @click="toggle" class="mb-3">
            <router-link to="/produits">Nos produits</router-link>
          </li>
          <li v-if="!end" @click="toggle" class="mb-3">
            <router-link to="/form">Je participe !</router-link>
          </li>
          <li @click="toggle" class="mb-3">
            <a
              href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/politique.pdf"
              target="_blank"
              >Confidentialité et cookies</a
            >
          </li>
          <li @click="toggle" class="mb-3">
            <a
              href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/reglement.pdf"
              target="_blank"
              >Règlement du jeu</a
            >
          </li>
          <li @click="toggle" class="mb-3">
            <router-link to="/mentions">Mentions légales</router-link>
          </li>
          <li @click="toggle" class="mb-3">
            <a href="https://www.cafegrandmere.fr/contact-us-page/" target="_blank">Contact</a>
          </li> 
          <li @click="toggle" class="mb-3">
            <a
              href="https://www.facebook.com/cafegrandmere/?locale=fr_FR"
              target="_blank"
              ><i class="text-2xl mt-2 fa-brands fa-facebook"></i
            ></a>
          </li>
        </ul>
      </div>
    </Drawer>
  </div>
</template>
<script>
import HeaderElem from "@/components/HeaderElem";
import FooterElem from "@/components/FooterElem";
import Drawer from "vue-simple-drawer";
import Vue from "vue";
const store = Vue.observable({ isEnded: true });

export default {
  name: "App",
  provide() {
    return {
      store
    };
  },
  data: function () {
    return {
      menu: false,
      open: false,
      end: this.$root.configOp.datas.end
    };
  },
  mounted() {
    const scriptElement = document.createElement("script");
    scriptElement.async = true;
    scriptElement.defer = true;
    scriptElement.src = "https://eu.umami.is/script.js";
    scriptElement.dataset.websiteId = "c2d94094-cbb4-43aa-8c5f-90fd1870973d";
    scriptElement.dataset.doNotTrack = "true";
    scriptElement.dataset.autoTrack = "true";
    document.body.append(scriptElement);

    const currentDate = new Date();
    const aprilFirst = new Date(currentDate.getFullYear(), 3, 1);

    if (currentDate <= aprilFirst) {
      sessionStorage.setItem("finish", "true");
    }
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
  },
  components: {
    HeaderElem,
    FooterElem,
    Drawer,
  },
};
</script>

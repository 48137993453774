<template>
  <main class="gen-fond">
    <StatusView v-if="id && uid && open" :id="id" :uid="uid" />
    <router-link
      to="/"
      class="block absolute h-[30%] w-[85%] bg-transparent z-10 inset-0 cursor-pointer tablet:hidden"
    />
    <div
      id="canvas"
      class="text-lg tablet:text-2xl text-white text-center m-auto absolute bg-transparent w-[1050px] -left-[5000px] z-50"
    >
      <div class="w-full m-auto relative">
        <div
          @input="setText"
          class="w-[450px] pt-[60px] top-[10px] absolute right-[50px] z-50 text-left p-2 LaBelle text-[25px] leading-[33px] text-black"
          contenteditable
        >
          <img
            src="@/assets/images/lignes.png"
            class="absolute w-full top-[78px] opacity-50 z-10"
          />

          <span
            class="relative z-20 px-6 block text-black"
            v-html="textWithBreaks"
          />
        </div>
        <img src="@/assets/images/cartefond.png" class="w-full" />
        <img
          v-if="!dataUrl"
          src="@/assets/images/changeavat.png"
          class="absolute top-[25%] w-[35%] left-[10%] cursor-pointer"
        />
        <img
          v-else
          :src="dataUrl"
          class="absolute top-[25%] w-[35%] left-[10%] cursor-pointer"
        />
      </div>
    </div>

    <div class="block tablet:hidden relative w-full max-w-[555px] m-auto">
      <div
        class="absolute z-50 bottom-[29vw] w-full bg-transparent cursor-pointer h-[15vw]"
        @click.prevent="screenshot"
      >
        &nbsp;
      </div>
      <div
        @click="resetText"
        class="w-[49%] top-[48.4%] h-[19%] left-[50%] absolute text-left LaBelle text-black overflow-hidden z-10"
      >
        <img
          src="@/assets/images/lignes.png"
          class="absolute w-[88%] left-[5%] top-0 noios h-auto z-10"
        />
        <textarea
          class="relative z-10  minitt noios text-black focus:outline-none w-full h-full bg-transparent"
          v-model="texte"
          maxlength="340"
        >
        </textarea>

        <textarea
          class="relative z-10  minitt ios text-black focus:outline-none w-[44vw] !p-0 !pl-[5%] h-[45vw] "
          style='background-image: url("/lignes2.png");   background-size : 100% 100%; '
          v-model="texte"
          maxlength="340"
        >
        </textarea>
      </div>

      <div
        class="h-[8%] z-10 w-[55%] left-[22%] bottom-[10%] cursor-pointer bg-transparent absolute"
      ></div>
      <input id="fileUpload" type="file" @change="onFileSelected" hidden />

      <p
        class="absolute top-[52%] w-[35%] max-w-[200px] left-[10%] cursor-pointer text-center text-white LaBelle"
       
      >
        Pour
      </p>
      <img
        v-if="!dataUrl"
        @click.prevent="chooseFiles()"
        src="@/assets/images/changeavat.png"
        class="absolute top-[52%] w-[35%] max-w-[200px] left-[10%] cursor-pointer"
      />
      <img
        v-else
        @click.prevent="chooseFiles()"
        :src="dataUrl"
        class="absolute top-[52%] w-[35%] max-w-[200px] left-[10%] cursor-pointer"
      />
      <img
        src="@/assets/images/carte-mobile-min.png"
        class="m-auto w-full max-w-xl"
      />
    </div>

    <!-- <img
      src="@/assets/images/starb.png"
      class="absolute bottom-0 left-[10%] hidden tablet:block"
    /> -->
    <div class="relative">
      <img
        src="@/assets/images/logo.png"
        class="m-auto p-0 mt-10 w-[200px] hidden tablet:block"
      />
      <p
        class="w-full text-center max-w-[600px] m-auto middle:max-w-[1920px] hidden tablet:block text-white mt-4 mb-2 angi-bold text-2xl"
      >
        SOUHAITEZ-LUI UNE BONNE FÊTE AVEC UNE CARTE PERSONNALISÉE
      </p>
    </div>
    <div
      class="w-full text-lg tablet:text-2xl text-white text-center m-auto overflow-hidden hidden tablet:block"
    >
      <div class="absolute w-full -top-12 block desktop:hidden z-20">
        <div
          class="w-full max-w-5xl m-auto relative flex justify-end top-[350px] pr-5"
        >
          <img src="@/assets/images/flechenoir.png" class="w-[15%]" />
        </div>
      </div>

      <div class="w-full max-w-5xl m-auto relative">
        <div
          @click="resetText"
          class="w-[43%] pt-[7.5%] top-[5%] h-[71%] absolute right-[4%] z-50 text-left p-2 LaBelle text-base leading-[134%] text-black"
        >
          <img
            src="@/assets/images/lignes.png"
            class="absolute w-full top-[14%] opacity-50 z-10"
          />

          <textarea
            class="relative z-20 px-6 block text-black focus:outline-none bg-transparent w-full h-full overflow-hidden"
            v-model="texte"
            maxlength="340"
          >
          </textarea>
          <!--<span
            @input="setText"
            contenteditable
            class="relative z-20 px-6 block text-black focus:outline-none"
            v-html="texte"
          /> -->
        </div>
        <img src="@/assets/images/cartefond.png" class="w-full" />
        <input id="fileUpload" type="file" @change="onFileSelected" hidden />
        <img
          v-if="!dataUrl"
          @click.prevent="chooseFiles()"
          src="@/assets/images/changeavat.png"
          class="absolute top-[25%] w-[35%] left-[10%] cursor-pointer"
        />
        <img
          v-else
          @click.prevent="chooseFiles()"
          :src="dataUrl"
          class="absolute top-[23%] w-[35%] left-[10%] cursor-pointer"
        />
        <img
          src="@/assets/images/carte-l.png"
          class="absolute w-[20%] bottom-[20%] -left-[17%] cursor-pointer ct1"
        />
        <img
          src="@/assets/images/carte-r.png"
          class="absolute w-[17%] top-[10%] -right-[16%] hidden desktop:block"
        />

        <img
          src="@/assets/images/infol.png"
          class="absolute w-[18%] top-[7%] left-[20%] cursor-pointer ct2"
        />
      </div>
    </div>

    <div
      class="w-full text-center mt-2 mb-16 hidden tablet:block relative z-50"
    >
      <button
        @click.prevent="screenshot"
        class="bg-white text-2xl middle:text-3xl px-4 py-2 rounded m-auto angi-bold text-[#d71823] uppercase relative z-50"
      >
        Je télécharge ma carte
      </button>
    </div>
  </main>
</template>

<script>
import html2canvas from "html2canvas";
import StatusView from "@/components/StatusView.vue";

export default {
  name: "ResultView",
  data() {
    return {
      open: false,
      id: undefined,
      uid: undefined,
      dataUrl: undefined,
      pseudo: undefined,
      init: 0,
      texte2:
        "Chère Mamie Val,\n\nJe voulais te dire que je pense à toi\net que je t’aime très fort ! Regarde,\nj’ai créé une mamie qui te ressemble.\nBon, tu es encore plus jolie en vrai\nmais je me suis dit que ça te ferait\nquand même plaisir.\nMerci pour toutes nos anecdotes,\nnos fous rires et ta recette de scones\ninégalable.\nJe te fais des gros bisous\n         Elisa ta petite-fille adorée",
      texte:
        "Chère Mamie Val,\n\nJe voulais te dire que je pense à toi\net que je t’aime très fort ! Regarde,\nj’ai créé une mamie qui te ressemble.\nBon, tu es encore plus jolie en vrai\nmais je me suis dit que ça te ferait\nquand même plaisir.\nMerci pour toutes nos anecdotes,\nnos fous rires et ta recette de scones\ninégalable.\nJe te fais des gros bisous\n         Elisa ta petite-fille adorée",
    };
  },
  computed: {
    textWithBreaks() {
      return this.texte.replaceAll(/\n/g, "<br>").replaceAll(/ /g, "&nbsp;");
    },
  },
  components: {
    StatusView,
  },
  mounted() {
    this.dataUrl = sessionStorage.getItem("avatar_b64") || undefined;
    this.pseudo = sessionStorage.getItem("avatar_pseudo");

    this.id = sessionStorage.getItem("id");
    this.uid = sessionStorage.getItem("uid");
    if (this.id && this.uid) {
      this.open = true;
    }
  },
  watch: {
    texte(value) {
      this.texte = this.limitLineBreaks(value, 13);
    },
  },
  methods: {
    tagMePart() {
      // eslint-disable-next-line no-undef
      umami.track('je telecharge ma carte');
      this.$gtag.event("click", {
        event_category: "bouton",
        event_label: "je telecharge ma carte'",
        value: "ok",
      });
      // eslint-disable-next-line no-undef
      fbq('track', 'TelechargementEtape2');
    },
    chooseFiles: function () {
      document.getElementById("fileUpload").click();
    },
    limitLineBreaks(text, maxLineBreaks) {
      let lines = text.split("\n");
      if (lines.length > maxLineBreaks) {
        return lines.slice(0, maxLineBreaks).join("\n");
      }
      return text;
    },
    async screenshot() {
      html2canvas(document.querySelector("#canvas"), {
        scale: 3,
        backgroundColor: null,
      }).then(async (canvas) => {
        let base64Image = canvas.toDataURL("image/png");
        sessionStorage.setItem("carte_b64", base64Image);
        sessionStorage.setItem("carte_texte", this.texte2);
        let blob = await fetch(base64Image).then((r) => r.blob());
        let blobURL = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = blobURL;
        link.download = "carte.png";
        link.click();
      });
      this.tagMePart();
      setTimeout(() => {
        this.$router.push("/produits");
      }, 2000);
    },
    setText(e) {
      this.texte2 = e.target.innerHTML;
    },
    onFileSelected(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = () => {
        this.dataUrl = reader.result;
      };
      reader.readAsDataURL(file);
    },
    resetText() {
      if (this.init == 0) {
        this.texte = "";
        this.init = 1;
      }
    },
    getResult() {},
    loading() {},
  },
};
</script>

<style lang="scss" scoped>
h1 {
  @apply font-bold text-4xl pb-2 pt-6;
  font-family: "ff-angie-pro", serif;
  font-weight: 700;
  font-style: normal;
}

.ct1 {
  @media screen and (max-width: 1510px) {
    @apply hidden;
  }
}

.ct2 {
  @media screen and (min-width: 1510px) {
    @apply hidden;
  }
}

.gen-fond {
  @media screen and (max-width: 555px) {
    background-image: none !important;
  }
}

.minitt {
  @apply px-6;
  font-family: "LaBelle";
  font-size: 14.5px;
  line-height: 17.5px;
  @media screen and (max-width: 600px) {
    @apply px-[4.5vw];

    font-size: 2.5vw;
    line-height: 3.15vw;

    @supports (-webkit-touch-callout: none) {
      font-size: 2.5vw;
      line-height: 3.48vw;
    }
  }
}




.ios {
  @apply hidden;
  @supports (-webkit-touch-callout: none) {
      @apply block;
    }
}

.noios {
  @apply block;
  @supports (-webkit-touch-callout: none) {
      @apply hidden;
    }
}
</style>
<template>
  <div
    v-if="id && uid && open && form"
    class="w-full h-full fixed bg-black inset-0 flex justify-center items-center bg-opacity-80"
    style="z-index: 9999999"
  >

    <div
      class="max-w-xl w-full bg-[#DA1F0D] p-4 shadow-amber-100 shadow-lg rounded-lg relative px-8"
    >
      <i
        class="fa-solid fa-xmark absolute text-white text-4xl right-3 top-2 cursor-pointer"
        @click="goTo()"
      ></i>

      <img
        src="@/assets/images/logo.png"
        class="m-auto w-full max-w-[180px] tablet:max-w-[230px]"
      />
      <h2
        v-if="form"
        class="text-white mt-4 angi-bold text-2xl tablet:text-2xl min-w-md uppercase text-center leading-7"
      >
       
      </h2>
      <div v-if="form" class="max-w-md m-auto">
        <Form
          v-if="id"
          id="formstatus"
          form_id="65a7ed1e435491863e7777e6"
          :uid="uid"
          :submission_id="id"
          server="https://api.odr.promo.dev"
          v-on:results="getResult"
          v-on:form_submit_loading="loading"
          @on_error="viewError"
          :hideError="hiderror"
        />
      </div>
      <div
        v-if="confirm"
        class="text-white mt-4 angi-bold m-auto text-xl max-w-md text-center"
      >
        Nous avons bien reçu votre image ! <br /><br />Si elle est conforme,
        vous recevrez votre boîte personnalisée d’ici quelques semaines
      </div>
    </div>
    <simplert :useRadius="true" :useIcon="true" ref="simplert"> </simplert>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["uid", "id"],
  data: function () {
    return {
      confirm: false,
      open: true,
      loading: false,
      hiderror: false,
      form: false,
      file: undefined,
      participation: undefined,
      end: this.$root.configOp.datas.end,
    };
  },

  async mounted() {
    let { data } = await axios.get(
      "https://gabapi.lucien.ai/api/v2/submissions/" +
        this.id +
        "?uid=" +
        this.uid
    );



    this.participation = data.participationValide;

    if (data.file_1) {
      this.file = data.file_1;
    //  this.confirm = true;
    } else if (data.receipt) {
      this.file = data.receipt;
  //    this.confirm = true;
    }

    if (data) {
      if (data.status == "GAGNE") {
        this.form = true;
      } else {
        this.form = false;
      }
    } else {
      this.open = false;
    }
  },
  methods: {
    viewError() {
      // this.error();
    },
    goTo() {
     
      this.$emit('onclose', true)
      this.open = false;
     
    },
    error() {
      let obj = {
        title: "Erreur",
        message: "Une erreur est survenue",
        type: "error",
        customCloseBtnText: "Fermer",
      };
      this.$refs.simplert.openSimplert(obj);
    },
    getResult(result) {
      if (result) {
        this.form = false;
        this.confirm = true;
        setTimeout(() => {
          // this.$router.push("/produits");
        }, 2000);
      } else {
        this.error();
      }
    },
  },
};
</script>

<style>
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.upload-btn {
  border: 2px solid gray;
  color: gray;

  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  width: 100%;
  background-color: antiquewhite !important;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#formstatus {
  @apply mt-4;
}

#formstatus label {
  @apply block text-left text-white text-xl;
}

#formstatus .builder-buttons {
  @apply w-full text-center my-4;
}

#formstatus .builder-buttons button {
  @apply rounded;
}

#formstatus .mention {
  @apply text-sm text-white;
}

.input_wrapper_file_2 {
  display: none !important;
}

.upload-btn-set {
  @apply text-yellow-200 block py-2;
}

.upload-btn-wrapper {
  background-color: blanchedalmond !important;
  @apply rounded-lg flex items-center justify-items-start px-6 py-3 text-black;
}

.delete-multiplefiles {
  @apply block mr-2;
}

.info {
  @apply text-yellow-100 py-6 text-center ;
}
</style>

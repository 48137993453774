<template>
  <div class="w-full relative z-10">
    <div class="nav w-full hidden tablet:block">
      <div class="px-12">
        <ul
          class="w-full max-w-8xl flex justify-between m-auto h-[45px] items-center font-bold text-lg middle:text-xl"
        >
          <li>
            <router-link to="/"><i class="fa-solid fa-house"></i></router-link>
          </li>
          <li>
            <router-link to="/produits">Nos produits</router-link>
          </li>
          <li v-if="!end"><a @click="tagMePart()" class="cursor-pointer">Je participe !</a></li>
          <li>
            <a
              href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/politique.pdf"
              target="_blank"
              >Confidentialité et cookies</a
            >
          </li>
          <li>
            <a
              href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/reglement.pdf"
              target="_blank"
              >Règlement du jeu</a
            >
          </li>
          <li><router-link to="/mentions">Mentions légales</router-link></li>
          <li><a href="https://www.cafegrandmere.fr/contact-us-page/" target="_blank">Contact</a></li>
          <li>
            <a
              href="https://www.facebook.com/cafegrandmere/?locale=fr_FR"
              target="_blank"
              ><img src="@/assets/images/facebook.png"  class="w-8 h-8 block lg:hidden" /><img src="@/assets/images/facebook2.png" class="w-8 h-8 hidden lg:block" /></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>


export default {
  data() {
    return {
      end: this.$root.configOp.datas.end
    };
  },
  methods: {
    tagMePart() {
      // eslint-disable-next-line no-undef
      umami.track('je participe');
      this.$gtag.event("click", {
        'event_category': "lien",
        'event_label': "je participe",
        'value': "ok"
      })
      this.$router.push("/form");

    }
  },
};
</script>
<template>
  <div>
    <cookie-law
      v-if="!cookie"
      buttonText="Accepter"
      v-on:accept="ThankYouMethod()"
      message="Ce site Web utilise des cookies pour vous garantir la meilleure expérience sur notre site Web."
      theme="royal"
    ></cookie-law>
  </div>
</template>
<script>
import CookieLaw from "vue-cookie-law";
export default {
  name: "FooterElem",
  components: { CookieLaw },
  data() {
    return {
      cookie: true,
    };
  },
  mounted() {
    let cookie = localStorage.getItem("cookiegm");
    if (cookie === null) this.cookie = false;
    if (cookie && cookie != null) {
      this.cookie = false;
      this.setStats();
    }
  },
  methods: {
    setStats() {
      window.dataLayer = window.dataLayer || [];
      // eslint-disable-next-line no-undef
      gtag("js", new Date());
      // eslint-disable-next-line no-undef
      gtag("config", "UA-59008763-8", {
        send_page_view: false,
      });



      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      // eslint-disable-next-line no-undef
      fbq("init", "204726974446956");
      // eslint-disable-next-line no-undef
      fbq("track", "PageView");
    },
    ThankYouMethod() {
      localStorage.setItem("cookiegm", "true");
      this.setStats();
    },
  },
};
</script>
<style>
button.Cookie__button {
  background-color: #da1f0d !important;
  color: #000;
  border: 1px solid #000;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
}
</style>

<template>
  <main class="gen-fond pb-20">
    <router-link to="/" class="block absolute h-[30%] w-[85%] bg-transparent z-10 inset-0 cursor-pointer tablet:hidden" />
    <StatusView v-if="id && uid" :id="id" :uid="uid" />
    <div>
      <img
        src="@/assets/images/logo.png"
        class="m-auto mt-6 tablet:mt-5 w-full max-w-[180px] tablet:max-w-[230px]"
      />
      <p
        v-if="lot === 'boite'"
        class="w-full max-w-[280px] tablet:max-w-none m-auto leading-9 text-center text-white mt-4 angi-bold text-2xl tablet:text-3xl uppercase"
      >
        Vous avez gagné une des 70 boîtes collectors !
      </p>
      <p
        v-else
        class="w-full max-w-[300px] tablet:max-w-none m-auto leading-7 tablet:leading-9 text-center text-white mt-2  angi-bold text-2xl tablet:text-2xl uppercase"
      >
        Créez l’image de votre grand-mère !
      </p>
    </div>

    <Generator />
    <simplert :useRadius="true" :useIcon="true" ref="simplert"> </simplert>
  </main>
</template>
<script>
import Generator from "@/components/avatar/Generator.vue";
import StatusView from "@/components/StatusView.vue";

export default {
  name: "App",
  data() {
    return {
      id: undefined,
      uid: undefined,
      lot: "perdu",
    };
  },
  components: {
    Generator,
    StatusView,
  },
  mounted() {
    this.lot = sessionStorage.getItem("lot") || "perdu";

    if (this.$route.params._sub_id) {
      this.id = this.$route.params._sub_id;
    }
    if (this.$route.params._key) {
      this.uid = this.$route.params._key;
    }
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.gen-fond {
  @media screen and (max-width: 1024px) {
    background-image: url("@/assets/images/fond-avatar-mob.png") !important;
    background-size: 100%;
    background-position: top center;
  }
}
</style>
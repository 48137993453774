<template>
  <main class="mt-6">
    <StatusView v-if="id && uid && open" :id="id" :uid="uid" @onclose="open=false;" />
    <div
      class="w-[709px] h-[921px] left-0 absolute canvas overflow-hidden -top-[5000px] z-50 bg-transparent"
      id="canvas"
    >
      <div class="!w-[820px] mt-12 -ml-32 relative">
        <div class="preview z-20 relative">
          <canvas
            ref="cnv"
            id="cnv"
            width="1650"
            height="1250"
            class="absolute z-50 top-0 left-0 w-full"
          />
        </div>
      </div>

      <div class="absolute bottom-[360px] left-0 h-4 w-full nav z-10"></div>
      <div
        class="pseudo absolute font-bold over break-words bottom-0 left-0 h-[360px] w-[709px] overflow-hidden bg-black z-0 text-white flex justify-center items-center angi-regular text-[130px] leading-[120px] text-center whitespace-pre-wrap"
        v-html="pseudo"
      />
    </div>
    <img
      src="@/assets/images/infos3.png"
      class="m-auto my-3 w-[250px] tablet:hidden"
    />
    <!-- <img
      src="@/assets/images/fullstar.png"
      class="absolute right-[15%] w-[200px] bottom-[1%] hidden desktop:block"
    /> -->
    <div class="w-full max-w-[1199px] m-auto relative">
      <img
        src="@/assets/images/infos.png"
        class="absolute -left-2 middle:-left-[10%] w-[200px] middle:w-[250px] -bottom-[20%] middle:-bottom-[25%] infst hidden desktop:block"
      />
      <!-- <img
        src="@/assets/images/help.png"
        class="absolute -left-[2%] w-[50px] -top-[4%] z-50 cursor-pointer hidden desktop:block"
        @click="warning"
      /> -->

      <div
        class="w-[320px] tablet:w-[380px] mmiddle:w-[480px] bg-white desktop:w-full desktop:max-w-5xl middle:max-w-7xl m-auto desktop:flex desktop:flex-row"
      >
        <div
          class="w-[320px] tablet:w-[380px] middle:w-[480px] h-[400px] tablet:h-[521px] middle:h-[621px] top-0 canvas overflow-hidden left-0 z-0 bg-white relative"
          id="canvas2"
          @click="clearAndFocus"
        >
          <svg
            class="absolute w-7 bottom-4 middle:bottom-14 right-4 z-50 curseur pointer"
            fill="#ffffff"
            height="200px"
            width="200px"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 306.637 306.637"
            xml:space="preserve"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <g>
                <g>
                  <path
                    d="M12.809,238.52L0,306.637l68.118-12.809l184.277-184.277l-55.309-55.309L12.809,238.52z M60.79,279.943l-41.992,7.896 l7.896-41.992L197.086,75.455l34.096,34.096L60.79,279.943z"
                  ></path>
                  <path
                    d="M251.329,0l-41.507,41.507l55.308,55.308l41.507-41.507L251.329,0z M231.035,41.507l20.294-20.294l34.095,34.095 L265.13,75.602L231.035,41.507z"
                  ></path>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </g>
            </g>
          </svg>

          <div
            class="w-[320px] tablet:w-[550px] tablet:-mt-5 -ml-10 tablet:-ml-28 relative"
          >
            <div class="preview z-20 relative hidden middle:block">
              <canvas
                ref="cnv4"
                id="cnv4"
                width="590"
                height="480"
                class="absolute z-50 top-7 left-7 w-full"
              />
            </div>
            <div
              class="preview z-20 relative hidden tablet:block middle:hidden"
            >
              <canvas
                ref="cnv2"
                id="cnv2"
                width="570"
                height="350"
                class="absolute z-50 top-5 left-0 w-full"
              />
            </div>
            <div class="preview z-20 relative block tablet:hidden">
              <canvas
                ref="cnv3"
                id="cnv3"
                width="320"
                height="250"
                class="absolute z-50 top-2 left-0 w-full"
              />
            </div>
          </div>
          <div
            class="absolute top-[205px] tablet:top-auto tablet:bottom-[248px] middle:top-auto middle:bottom-[265px] left-0 h-3 w-full nav z-10"
          ></div>

          <textarea
            id="myTextarea"
            class="tablet:pt-16 pt-12 pseudo absolute over break-words -bottom-0 left-0 h-[185px] tablet:h-[250px] middle:h-[265px] w-full overflow-hidden bg-black z-0 text-white flex justify-center items-center angi-regular text-[55px] tablet:text-[80px] leading-[55px] tablet:leading-[80px] text-center"
            v-model="pseudo"
            maxlength="22"
          >
          </textarea>
        </div>
        <div
          class="configurator_wrapper relative desktop:w-[620px] middle:w-[720px] px-1 tablet:px-4 py-1 tablet:py-2"
        >
          <ul class="nav2">
            <li
              @click="
                type = 'visage';
                sub = 'forme';
              "
              :class="{ active: type === 'visage' }"
            >
              VISAGE
            </li>
            <li
              @click="
                type = 'yeux';
                sub = 'forme';
              "
              :class="{ active: type === 'yeux' }"
            >
              YEUX
            </li>
            <li
              @click="
                type = 'cheveux';
                sub = 'coiffure';
              "
              :class="{ active: type === 'cheveux' }"
            >
              CHEVEUX
            </li>
            <li
              @click="
                type = 'tenue';
                sub = 'clothes';
              "
              :class="{ active: type === 'tenue' }"
            >
              TENUE
            </li>
          </ul>

          <div class="options">
            <ul v-if="type === 'visage'" class="nav border-0">
              <li
                @click="sub = 'forme'"
                :class="{ active: sub === 'forme' && type === 'visage' }"
              >
                FORME
              </li>
              <li
                @click="sub = 'couleur'"
                :class="{ active: sub === 'couleur' && type === 'visage' }"
              >
                COULEUR
              </li>
              <li
                @click="sub = 'bouche'"
                :class="{ active: sub === 'bouche' && type === 'visage' }"
              >
                BOUCHE
              </li>
              <li
                @click="sub = 'nez'"
                :class="{ active: sub === 'nez' && type === 'visage' }"
              >
                NEZ
              </li>
              <li
                @click="sub = 'oreilles'"
                :class="{ active: sub === 'oreilles' && type === 'visage' }"
              >
                OREILLES
              </li>
            </ul>
            <ul v-if="type === 'yeux'" class="nav">
              <li
                @click="sub = 'forme'"
                :class="{ active: sub === 'forme' && type === 'yeux' }"
              >
                FORME
              </li>
              <li
                @click="sub = 'couleur'"
                :class="{ active: sub === 'couleur' && type === 'yeux' }"
              >
                COULEUR
              </li>
              <li
                @click="sub = 'sourcils'"
                :class="{ active: sub === 'sourcils' && type === 'yeux' }"
              >
                SOURCILS
              </li>
            </ul>
            <ul v-if="type === 'cheveux'" class="nav">
              <li
                @click="sub = 'coiffure'"
                :class="{ active: sub === 'coiffure' && type === 'cheveux' }"
              >
                COIFFURE
              </li>
              <li
                @click="sub = 'couleur'"
                :class="{ active: sub === 'couleur' && type === 'cheveux' }"
              >
                COULEUR
              </li>
              <li
                @click="sub = 'reflet'"
                :class="{ active: sub === 'reflet' && type === 'cheveux' }"
              >
                COULEUR REFLETS
              </li>
            </ul>
            <ul v-if="type === 'tenue'" class="nav">
              <li
                @click="sub = 'clothes'"
                :class="{ active: sub === 'clothes' && type === 'tenue' }"
              >
                VÊTEMENTS
              </li>
              <li
                @click="sub = 'cup'"
                :class="{ active: sub === 'cup' && type === 'tenue' }"
              >
                TASSE
              </li>
              <li
                @click="sub = 'accessoire'"
                :class="{ active: sub === 'accessoire' && type === 'tenue' }"
              >
                ACCESSOIRES
              </li>
            </ul>
          </div>

          <div class="prev">
            <ul
              v-if="sub === 'reflet' && type === 'cheveux'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li @click="avatar.hairhighlightcolor = 14">
                <img
                  src="/none.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
              </li>
              <li
                v-for="i in 13"
                :key="i"
                :class="[
                  `bg-hairhighlightcolor${i}  border `,
                  {
                    'border-black border shadow-lg':
                      avatar.hairhighlightcolor === i,
                  },
                ]"
                @click="avatar.hairhighlightcolor = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'couleur' && type === 'visage'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 14"
                :key="i"
                :class="[
                  `bg-skincolor${i} border`,
                  { 'border-[#d5ac5477] border  bx': avatar.skin === i },
                ]"
                @click="avatar.skin = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'couleur' && type === 'yeux'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 10"
                :key="i"
                :class="[
                  `bg-eyescolor${i} border `,
                  {
                    'border-[#d5ac5477] border  bx': avatar.eyescolors === i,
                  },
                ]"
                @click="avatar.eyescolors = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'couleur' && type === 'cheveux'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 13"
                :key="i"
                :class="[
                  `bg-haircolor${i} border `,
                  {
                    'border-[#d5ac5477] border  bx': avatar.hairscolor === i,
                  },
                ]"
                @click="avatar.hairscolor = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'forme' && type === 'visage'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 11"
                @click="avatar.head = i"
                :key="i"
                class="bg-white border relative cursor-pointer hd"
                :class="{
                  ' border-[#d5ac5477] border bg-gray-200 bx':
                    avatar.head === i,
                }"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  class="prev_head"
                  :src="require(`@/assets/vectors/HEADS/HEAD${i}.svg`)"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'bouche' && type === 'visage'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 11"
                :key="i"
                class="bg-white border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx':
                    avatar.mouth === i,
                }"
                @click="avatar.mouth = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  class="prev_mouth"
                  :src="require(`@/assets/vectors/MOUTHS/MOUTH${i}.svg`)"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'nez' && type === 'visage'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 11"
                :key="i"
                class="bg-white border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx': avatar.nose === i,
                }"
                @click="avatar.nose = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  v-if="i < 11"
                  class="prev_nose"
                  :src="require(`@/assets/vectors/NOSES/NOSE${i}.svg`)"
                />
                <inline-svg
                  v-else
                  class="prev_nose"
                  :src="require(`@/assets/vectors/NOSES/NOSE11B.svg`)"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'oreilles' && type === 'visage'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 8"
                :key="i"
                class="bg-white border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx': avatar.ears === i,
                }"
                @click="avatar.ears = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  class="prev_ears"
                  :src="require(`@/assets/vectors/EARS/EARS${i}.svg`)"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'forme' && type === 'yeux'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 9"
                :key="i"
                class="bg-white border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx': avatar.eyes === i,
                }"
                @click="avatar.eyes = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  class="prev_eyes"
                  :src="require(`@/assets/vectors/EYES/EYES${i}.svg`)"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'sourcils' && type === 'yeux'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 11"
                :key="i"
                class="bg-white rounded border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx':
                    avatar.eyebrows === i,
                }"
                @click="avatar.eyebrows = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  class="prev_eyebrows"
                  :src="require(`@/assets/vectors/EYEBROWS/EYEBROWS${i}.svg`)"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'coiffure' && type === 'cheveux'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 12"
                :key="i"
                class="bg-white rounded border relative cursor-pointer hr"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx':
                    avatar.hairs === i,
                }"
                @click="avatar.hairs = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  class="prev_hairs"
                  :src="require(`@/assets/vectors/HAIRS/HAIR${i}.svg`)"
                />
              </li>
            </ul>

            <ul
              v-if="sub === 'clothes' && type === 'tenue'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 12"
                :key="i"
                class="bg-white border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx':
                    avatar.clothe === i,
                }"
                @click="avatar.clothe = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <inline-svg
                  class="prev_clothes"
                  :src="require(`@/assets/vectors/CLOTHES/CLOTHE${i}.svg`)"
                />
              </li>
            </ul>
            <ul
              v-if="sub === 'cup' && type === 'tenue'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li
                v-for="i in 12"
                :key="i"
                class="bg-white border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx': avatar.cup === i,
                }"
                @click="avatar.cup = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <img
                  class="prev_cups"
                  :src="`/images/avatar/cups/CUP${i}.png`"
                />
              </li>
            </ul>
            <ul
              v-if="sub === 'accessoire' && type === 'tenue'"
              class="tablet:grid tablet:grid-cols-5 flex gap-4 mt-4 w-full"
            >
              <li @click="avatar.accessorie = 14">
                <img
                  src="/none.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
              </li>
              <li
                v-for="i in 12"
                :key="i"
                class="bg-white border relative cursor-pointer"
                :class="{
                  'border-[#d5ac5477] border bg-gray-200 bx':
                    avatar.accessorie === i,
                }"
                @click="avatar.accessorie = i"
              >
                <img
                  src="/px.png"
                  class="w-[100px] tablet:w-full cursor-pointer"
                />
                <img
                  class="absolute inset-0 w-[90%] mt-[5 ml-[5%]"
                  :src="`/images/${i}.png`"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <img
      src="@/assets/images/infos2.png"
      class="m-auto mt-3 w-[250px] tablet:hidden"
    /> -->

    <div class="w-full text-center mt-8 middle:mt-12">
      <button
        class="bg-white text-lg tablet:text-xl middle:text-3xl px-3 tablet:px-6 py-3 rounded m-auto angi-bold text-[#d71823] uppercase"
        @click="screenshot"
      >
        J’enregistre <br class="block tablet:hidden" />mon avatar !
      </button>
      <br />
      <a 
 
        @click="openPopup"
        v-if="id && uid && idok && !end"
        class="text-white m-auto angi-bold block text-lg tablet:text-3xl underline my-8"
        >J'ENVOIE MON IMAGE !</a
      >
    </div>

    <simplert :useRadius="true" :useIcon="true" ref="simplert"> </simplert>
  </main>
</template>
<script>
import StatusView from "@/components/StatusView.vue";
import html2canvas from "html2canvas";
import { Canvg } from "canvg";
import axios from "axios";

export default {
  name: "AppGen",
  components: {
    StatusView,
  },
  data() {
    return {
      open: false,
      id: undefined,
      uid: undefined,
      pseudo: "GRAND'\nMÈRE",
      idok: false,
      end: this.$root.configOp.datas.end,
      avatar: {
        skin: 1,
        nose: 1,
        head: 1,
        hairs: 1,
        hairscolor: 1,
        cup: 1,
        clothe: 1,
        accessorie: 1,
        eyes: 1,
        eyebrows: 1,
        eyescolors: 1,
        mouth: 1,
        ears: 1,
        hairhighlightcolor: 1,
      },
      sub: "forme",
      type: "visage",
      hairhighlightcolor: [
        "#FFFFFF",
        "#FEB71F",
        "#5AC4F4",
        "#EE7DB0",
        "#838588",
        "#c4c4c8",
        "#e93c2b",
        "#b173b0",
        "#f59219",
        "#3f2518",
        "#a7725f",
        "#81C475",
        "#B99B21",
        "transparent",
      ],
      hairscolors: [
        "#ffffff",
        "#c4c5c8",
        "#a8adaf",
        "#838589",
        "#343839",
        "#bb9f8f",
        "#94663d",
        "#fff98c",
        "#fec657",
        "#6a2717",
        "#3f2518",
        "#ed7db0",
        "#b073af",
      ],
      skincolors: [
        "#fcc9b1",
        "#fab586",
        "#fddac8",
        "#dfc2b3",
        "#f59368",
        "#f27b2c",
        "#b76650",
        "#a74129",
        "#964420",
        "#B77F58",
        "#3f2518",
        "#e1881c",
        "#fcbc5d",
        "#ffe48f",
      ],
      eyescolors: [
        "#b89b21",
        "#412418",
        "#501A12",
        "#68482A",
        "#127BAA",
        "#5AC4F3",
        "#2A673C",
        "#3E9658",
        "#605D47",
        "#B174AF",
      ],
    };
  },
  watch: {
    avatar: {
      deep: true,
      handler: async function () {
        await this.updateCanvas();
      },
    },
  },
  computed: {
    hairFill() {
      return this.hairscolors[this.avatar.hairscolor - 1];
    },
    skinFill() {
      return this.skincolors[this.avatar.skin - 1];
    },
    eyesFill() {
      return this.eyescolors[this.avatar.eyescolors - 1];
    },
    hair2Fill() {
      return this.hairhighlightcolor[this.avatar.hairhighlightcolor - 1];
    },
  },
  async mounted() {
    const canvas = this.$refs.cnv;
    const ctx = canvas.getContext("2d");
    ctx.scale(2, 2);
    await this.updateCanvas();

    let _id = this.$route.query.id;
    let uid = this.$route.query.uid;

    let open = this.$route.query.open;
    if (open) this.open = open;

    if (_id && uid) {
      this.id = _id;
      sessionStorage.setItem("id", _id);
      this.uid = uid;
      sessionStorage.setItem("uid", uid);

      let { data } = await axios.get(
        "https://gabapi.lucien.ai/api/v2/submissions/" +
          this.id +
          "?uid=" +
          this.uid
      );
      if (data.status === "GAGNE") {
        this.idok = true;
      }
    }

    this.id = sessionStorage.getItem("id");
    this.uid = sessionStorage.getItem("uid");

    if(this.id && this.uid) {
      this.idok = true;
    }
  },
  methods: {
    tagMePart() {
      // eslint-disable-next-line no-undef
      umami.track('j enregistre mon image');
      this.$gtag.event("click", {
        'event_category': "bouton",
        'event_label': "j'enregistre mon image'",
        'value': "ok"
      })
      // eslint-disable-next-line no-undef
      fbq('track', 'TelechargementEtape1'); 
    },
    tagMeUpdate() {
      // eslint-disable-next-line no-undef
      umami.track('j envoi mon image');
      this.$gtag.event("click", {
        'event_category': "bouton",
        'event_label': "j'envoi mon image'",
        'value': "ok"
      })
      // eslint-disable-next-line no-undef
      fbq('track', 'TelechargementEtape1'); 
    },
    openPopup() {
      this.open = "true";
      this.tagMePart();

    },
    clearAndFocus() {
      let textarea = document.getElementById("myTextarea");
      textarea.value = "";
      textarea.focus();
    },
    async updateCanvas() {
      await this.allCanvas(this.$refs.cnv);
      await this.allCanvas(this.$refs.cnv2);
      await this.allCanvas(this.$refs.cnv3);
      await this.allCanvas(this.$refs.cnv4);
    },
    async allCanvas(ref) {
      const canvas = ref;
      const ctx = canvas.getContext("2d");
      let canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/HEADS/HEAD${this.avatar.head}.svg`).default.replaceAll(
          'class="skin"',
          `fill="${this.skinFill}"`
        )
      );
      canvg.render();
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/EYEBROWS/EYEBROWS${this.avatar.eyebrows}.svg`)
          .default
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/HAIRS/HAIR${this.avatar.hairs}.svg`)
          .default.replaceAll('class="haircolor"', `fill="${this.hairFill}"`)
          .replaceAll('class="reflet"', `fill="${this.hair2Fill}"`)
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/EYES/EYES${this.avatar.eyes}.svg`).default.replaceAll(
          'class="eyescolor"',
          `fill="${this.eyesFill}"`
        )
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/MOUTHS/MOUTH${this.avatar.mouth}.svg`)
          .default
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/EARS/EARS${this.avatar.ears}.svg`)
          .default
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/NOSES/NOSE${this.avatar.nose}.svg`).default.replaceAll(
          'class="skin"',
          `fill="${this.skinFill}"`
        )
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/ACCESSORIES/ACCESSORIES${this.avatar.accessorie}.svg`)
          .default
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/CLOTHES/CLOTHE${this.avatar.clothe}.svg`)
          .default
      );
      canvg.render({ ignoreClear: true });
      canvg = Canvg.fromString(
        ctx,
        require(`!!raw-loader!@/assets/vectors/CUPS/CUP${this.avatar.cup}.svg`)
          .default
      );
      canvg.render({ ignoreClear: true });
    },
    warning() {
      let obj = {
        title: "Attention !",
        message: "A définir",
        type: "warning",
      };
      this.$refs.simplert.openSimplert(obj);
    },
    async screenshot() {
      html2canvas(document.querySelector("#canvas"), {
        scale: 3,
        backgroundColor: null,
      }).then(async (canvas) => {
        let base64Image = canvas.toDataURL("image/png");
        sessionStorage.setItem("avatar_b64", base64Image);
        sessionStorage.setItem("avatar_pseudo", this.pseudo2);
        let blob = await fetch(base64Image).then((r) => r.blob());
        let blobURL = URL.createObjectURL(blob);
        let link = document.createElement("a");
        link.href = blobURL;
        link.download = "image-grand-mere.png";
        link.click();
      });

      this.tagMePart();

      setTimeout(() => {
        window.location.href = "/carte";
       // this.$router.push("/carte");
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
.contour {
  fill: #cdac02;
}

.reflet {
  fill: v-bind("hair2Fill") !important;
}

.haircolor {
  fill: v-bind("hairFill") !important;
}

.eyescolor {
  fill: v-bind("eyesFill") !important;
}

.skin {
  fill: v-bind("skinFill") !important;
}

.pseudo {
  word-break: break-all;
}

.infst {
  @media screen and (max-width: 1500px) {
    left: 0 !important;
    z-index: 9999;
  }
}
</style>

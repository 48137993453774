import Vue from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";

import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

import Dayjs from "vue-dayjs";
Vue.use(Dayjs);

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  appName: "Grand Mère - Grand jeu anniversaire",
  pageTrackerScreenviewEnabled: true,
  config: { id: "UA-59008763-8" },
});

import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";

Vue.use(Simplert);

const pinia = createPinia();
Vue.use(pinia);

import { InlineSvgPlugin } from "vue-inline-svg";
Vue.use(InlineSvgPlugin);

import "./assets/style/style.scss";

Vue.config.productionTip = false;




const currentDate = new Date();
const aprilFirst = new Date(currentDate.getFullYear(), 3, 1);
let end = false;
if (currentDate >= aprilFirst) end = true;

let params = new URLSearchParams(window.location.search);
let myParam = params.get('endend'); 

if(myParam==="true")  {
  sessionStorage.setItem("endend", true);
  end = true;
}

if(sessionStorage.getItem("endend")==="true")  {
  end = true;
}
const init = { end: end };

new Vue({
  data: {
    configOp: {
      datas: init,
    },
  },
  router,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Avatar from "../views/Generateur.vue";
import Contact from "../views/ContactView.vue";
import Mentions from "../views/MentionsView.vue";
import Dotations from "../views/DotationsView.vue";
import Form from "../views/FormView.vue";
import Carte from "../views/CarteView.vue";
import Result from "../views/ResultView.vue";
import Wait from "../views/WaitView.vue";
import Finish from "../views/FinishView.vue";
import Image from "../views/ImageView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/avatar",
    name: "avatar",
    component: Avatar,
  },
  {
    path: "/image",
    name: "image",
    component: Image,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/produits",
    name: "dotations",
    component: Dotations,
  },
  {
    path: "/mentions",
    name: "mentions",
    component: Mentions,
  },
  {
    path: "/form",
    name: "form",
    component: Form,
  },
  {
    path: "/result",
    name: "result",
    component: Result,
  },
  {
    path: "/carte",
    name: "cartes",
    component: Carte,
  },
  {
    path: "/finish",
    name: "finish",
    component: Finish,
  },
  {
    path: "/status/:_sub_id/:_key",
    name: "status",
    component: () => import("../views/StatusView.vue"),
  },
  {
    path: "/wait",
    name: "wait",
    component: Wait,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

import { render, staticRenderFns } from "./ImageView.vue?vue&type=template&id=592f5fd8&scoped=true"
var script = {}
import style0 from "./ImageView.vue?vue&type=style&index=0&id=592f5fd8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "592f5fd8",
  null
  
)

export default component.exports
<template>
  <main class="fond-mix">
    <LoadingCp v-if="load" />
    <div class="w-full pt-2 relative">
      <img
        src="@/assets/images/form-left.png"
        class="absolute top-0 w-[7.8vw] left-0 max-w-[250px] hidden tablet:block "
      />

      <img
        src="@/assets/images/form-left2.png"
        class="absolute w-[7vw] bottom-0 left-[42vw] max-w-[160px]"
      />

      <div class="w-full" />
      <div
        class="w-full m-auto h-full tablet:grid tablet:grid-cols-2 text-white -mt-[1px] max-w-[2000px] relative"
      >
        <div class="relative tablet:hidden">
          <img
            src="@/assets/images/mobile-form.png"
            class="m-auto w-full max-w-[555px] tablet:hidden"
          />
          <router-link to="/" class="block absolute h-[30%] w-[85%] bg-transparent z-10 inset-0 cursor-pointer tablet:hidden" />
          <div class="w-full absolute left-0 bottom-[10%]">
            <p class="w-full text-center text-sm tablet:hidden">
              *règlement du jeu sur
              <a
                class="underline"
                href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/reglement.pdf"
                target="_blank"
                >www.cafegrandmere.fr</a
              >
            </p>
          </div>
        </div>

        <div
          class="absolute top-[95vw] mobile:top-[550px] h-[56vw] max-h-[320px] w-full overflow-y-hidden overflow-x-auto m-auto scb tablet:hidden"
        ></div>

        <div class="relative hidden tablet:block">
          <img
            src="@/assets/images/left.png"
            class="max-w-[655px] middle:max-w-[795px] pl-[6%] w-full m-auto"
          />
          <div class="flex justify-center flex-wrap mt-6">
            <p class="w-full text-center text-lg my-8">
              *règlement du jeu sur
              <a
                class="underline"
                href="https://oxoformdocs.fra1.digitaloceanspaces.com/6598142d44ca429fac984e7f/reglement.pdf"
                target="_blank"
                >www.cafegrandmere.fr</a
              >
            </p>
          </div>
        </div>
        <div class="relative bg-black tablet:bg-transparent -mt-16 tablet:mt-0">
          <div
            class="px-4 tablet:px-0 tablet:pl-20 tablet:pr-4 desktop:pr-0 desktop:pl-0"
          >
            <Form
              :class="'form_step' + step"
              id="monform"
              form_id="6598142d44ca429fac984e85"
              server="https://api.odr.promo.dev"
              v-on:results="getResult"
              v-on:form_submit_loading="loading"
              @on_error="viewError"
              :hideError="hiderror"
            />

            <div
              class="mt-12 max-w-lg desktop:max-w-xl m-auto text-justify text-xs leading-4 tablet:mb-0 pb-6"
            >
              Conformément à la législation en vigueur, remplir ce formulaire
              entraîne le traitement de vos données personnelles par Promodev,
              fondé sur votre consentement, uniquement afin de traiter votre
              participation à cette opération. Pour l’exercice de vos droits, la
              durée de conservation ou tout autre renseignement, retrouvez
              toutes les informations ici.
            </div>
          </div>
        </div>
      </div>
    </div>
    <simplert :useRadius="true" :useIcon="true" ref="simplert"> </simplert>
  </main>
</template>
<script>
import LoadingCp from "@/components/LoadingView.vue";
import { RouterLink } from "vue-router";
export default {
  name: "App",
  data() {
    return {
      hidden: true,
      load: false,
      step: 1,
      submited: false,
      hiderror: true,
      server: undefined,
    };
  },
  components: {
    LoadingCp,
    RouterLink
},
  mounted() {},
  methods: {
    getResult(result) {
      if (result) {
        this.load = false;
        sessionStorage.setItem("id", result._id);
        sessionStorage.setItem("uid", result.uid);

        if (result.status === "GAGNE" && result.lot === "boite") {
          sessionStorage.setItem("lot", "boite");
        } else {
          sessionStorage.setItem("lot", "perdu");
        }
        // eslint-disable-next-line no-undef
        fbq('track', 'Lead');
        this.$router.push("/result");
      } else {
        this.error();
        this.load = false;
      }
    },
    loading() {
      this.load = true;
    },
    viewError() {
      // this.error();
    },
    error() {
      let obj = {
        title: "Erreur",
        message: "Une erreur est survenue",
        type: "error",
        customCloseBtnText: "Fermer",
      };
      this.$refs.simplert.openSimplert(obj);
    },
  },
};
</script>

<style>
.scb::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
.scb::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
.scb::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 25px;
}

/* Handle on hover */
.scb::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>